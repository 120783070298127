.help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 2.5em;
    margin: auto;
    background: #d80056;
}

body.transparent-bg {
    background-color: rgba(255, 255, 255, 1);
}

.public-header {
    background-color: #d80056;
    color:#FFF;
    border-color: #FFF;
}

.public-footer {
    background-color: #ed5666;
    color:#FFF;
    border-color: #FFF;
}


.introjs-tooltip {
    min-width: 700px;
    max-width: 900px;
}

.public-button-gray {
    background-color: lightgray;
    color:#444;
    margin:1px;
}
.public-button-gray:hover {
    background-color: #ed5666;
    color:#FFF;
    margin:1px;
}

.public-button-logo {
    background-color: #ed5666;
    color:#FFF;
    border-color: #FFF;
}

.public-button-logo:hover {
    background-color: #FFF;
    color:#000;
    border-color: #ed5666;
}

.public-button-reschedule {
    background-color: #FFF;
    color:#444;
    border-color: #444;
}

.public-button-reschedule:hover {
    background-color: #444;
    color:#FFF;
    border-color: #FFF;
}


.preview-footer {
    height: 20px;
    background-color: #444444;
}

.preview-header {
    background-image: url("/content/images/mail-header-bg.jpg");
}

.color-picker {
    z-index: 3300;
}

.iti-flag {background-image: url("/content/images/flags.png");}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {background-image: url("/content/images/flags@2x.png");}
}

@media print {
    body, .CLASS-of-parent-tag, #tableToPrint {
        display: block !important;
        position: relative !important;
        width: auto !important;
        height: auto !important;
        overflow: visible !important;
        margin-left: 0 !important;
    }
}

/* start Clover e-commerce css */
.container {
    background-color: #FFFFFF;
    /*border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 0 6px 0 rgba(141,151,158,0.2);*/
    padding: 16px;
    width: 460px;
}

.container * {
    font-family: Roboto, "Open Sans", sans-serif;
    font-size: 14px;
}

.container .form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container .form-row.top-row {
    margin-top: 8px;
}

.input-errors {
    font-size: 10px;
    position: absolute;
    margin-left: 5px;
    margin-top: 35px;
    color: red;
}

.container .form-row .field {
    box-sizing: border-box;
    border: 1px solid #DEE0E1;
    border-radius: 3px;
    height: 32px;
    margin-bottom: 20px;
    padding: 5px;
    width: 60%;
}

.container .button-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.container .button-container button {
    background-color: #ed5666;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    display: block;
    height: 32px;
    width: 300px;
}

.container .button-container button:disabled {
    background-color: #999999;
    color: #FFFFFF;
}

.container .button-container button:disabled:hover {
    background-color: #999999;
    color: #FFFFFF;
    border: none;
}

.container .button-container button:hover {
    background-color: #FFFFFF;
    color: #000000;
    border: thin solid #ed5666;
    border-radius: 3px;
}

@media (min-width: 350px) {
    .container {
        height: 250px;
    }

    .container .form-row .field.card-number {
        width: 300px;
    }

    container .form-row .field.card-cvv {
        width: 90px;
    }

    .container .form-row .field.third-width {
        width: 145px;
    }
}

/* end Clover e-commerce*/

/***************************** Dropzone Styling *****************************/

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
.advancedDemo .dropzone ul[dnd-list] {
    min-height: 42px;
    margin: 0px;
    padding-left: 0px;
}

.advancedDemo .dropzone li {
    display: block;
}

/**
 * Reduce opacity of elements during the drag operation. This allows the user
 * to see where he is dropping his element, even if the element is huge. The
 * .dndDragging class is automatically set during the drag operation.
 */
.advancedDemo .dropzone .dndDragging {
    opacity: 0.7;
}

/**
 * The dndDraggingSource class will be applied to the source element of a drag
 * operation.
 */
.advancedDemo .dropzone .dndDraggingSource {
    opacity: 0.5;
}

/**
 * An element with .dndPlaceholder class will be added as child of the dnd-list
 * while the user is dragging over it.
 */
.advancedDemo .dropzone .dndPlaceholder {
    background-color: #ddd !important;
    display: block;
    min-height: 42px;
}

/***************************** Element type specific styles *****************************/

.advancedDemo .dropzone .itemlist {
    min-height: 50px !important;
}

.advancedDemo .dropzone .itemlist > li {
    border: 3px solid;
    border-radius: .25em;
    color: #000;
    float: left;
    font-weight: 700;
    height: 33px;
    margin: 2px;
    padding: 5px;
    text-align: center;
    text-align-all: center;
    width: 140px;
}

.advancedDemo .dropzone .container-element {
    margin: 10px;
}

.box-yellow {
    border-color: #faebcc;
}
.box {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
}

.box-blue {
    border-color: #bce8f1;
}

/***************************** END Dropzone Styling *****************************/

/***************************** SeatOn Tooltip *****************************/

.seaton-tooltip {
    position: relative;
    display: inline-block;
}
/* INFO */
.seaton-tooltip .seaton-tooltiptext-info {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #FFF;
    color: #333;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #1ab394;
    border-style: solid;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.seaton-tooltip:hover .seaton-tooltiptext-info {
    visibility: visible;
    opacity: 1;
}

/* SUCCESS */
.seaton-tooltip .seaton-tooltiptext-success {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #FFF;
    color: #333;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #1c84c6;
    border-style: solid;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.seaton-tooltip:hover .seaton-tooltiptext-success {
    visibility: visible;
    opacity: 1;
}

/* WARNING */
.seaton-tooltip .seaton-tooltiptext-warning {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #FFF;
    color: #333;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #f8ac59;
    border-style: solid;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.seaton-tooltip:hover .seaton-tooltiptext-warning {
    visibility: visible;
    opacity: 1;
}

/* DANGER */
.seaton-tooltip .seaton-tooltiptext-danger {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #FFF;
    color: #333;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #ed5666;
    border-style: solid;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.seaton-tooltip:hover .seaton-tooltiptext-danger {
    visibility: visible;
    opacity: 1;
}

/* DEFAULT */
.seaton-tooltip .seaton-tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #FFF;
    color: #333;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #333;
    border-style: solid;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.seaton-tooltip:hover .seaton-tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* DEFAULT */
.seaton-tooltip .seaton-tooltiptext-large {
    visibility: hidden;
    position: absolute;
    width: 300px;
    background-color: #FFF;
    color: #333;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    border-width: 1px;
    border-color: #333;
    border-style: solid;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.seaton-tooltip:hover .seaton-tooltiptext-large {
    visibility: visible;
    opacity: 1;
}



.seaton-tooltip-right {
    top: -5px;
    left: 125%;
}

.seaton-tooltip-right::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.seaton-tooltip-bottom {
    top: 135%;
    left: 50%;
    margin-left: -60px;
}

.seaton-tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.seaton-tooltip-bottom-large {
    top: 135%;
    left: 50%;
    margin-left: -150px;
}

.seaton-tooltip-bottom-note::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.seaton-tooltip-top {
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
}

.seaton-tooltip-top-large {
    bottom: 125%;
    left: 50%;
    margin-left: -150px;
}

.seaton-tooltip-top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.seaton-tooltip-left {
    top: -5px;
    bottom:auto;
    right: 128%;
}
.seaton-tooltip-left::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}

/***************************** End SeatOn Tooltip *****************************/

.navbar-form-custom {
    float: left;
    height: 50px;
    padding: 0;
    width: 350px;
    display: block;
}

/************************ Embeded datepicker **********************************/
.embedded-daterangepicker .daterangepicker::before,
.embedded-daterangepicker .daterangepicker::after {
    display: none;
}
.embedded-daterangepicker .daterangepicker {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    float: left;
    width: 80%;
    margin-top: 0;
}
.embedded-daterangepicker .daterangepicker .drp-calendar {
    width: 50%;
    max-width: 50%;
}

.embedded-daterangepicker .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #ec4758;
    border-color: transparent;
    color: #fff;
}



